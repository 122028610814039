import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import { partners, forUsPages } from '../../data/data';
import loadable from '@loadable/component';

const SubMenu = loadable(() => import('../../components/subMenu'));
const Content = loadable(() => import('../../components/content'));

const History = ({ location }) => {
  const metaData = {
    title: 'История'
  };

  return (
    <Layout metaData={metaData}>
      <SubMenu location={location.pathname} sectionItems={forUsPages} />
      <Content>
        <p>
          Основният и постоянен предмет на дейност на фирмата ЕТ &quot;МАРИЯ СТОЯНОВА АРХ&quot;, регистрирана през 1994
          год. и АРХИТЕКТУРНО СТУДИО СТОЯНОВИ ЕООД е проектирането на обществени и жилищни сгради, вътрешно обзавеждане
          и интериорен дизайн. Постоянна практика на Архитектурно бюро при разработването на значими и отговорни обекти
          се явява сформирането на творчески архитектурни колективи за съответната задача.
        </p>
        <p>
          Характерно при организиране на работата по изработване на проекти за сгради, е това че за всеки от обектите,
          според неговия обем и характер се сформират различни колективи от специалисти от съответните професии
          участници в проекта. Организатор на дейността и водеща функция при изработването на проекта има архитектурната
          група от екипа. По време на изработване на инвестиционните проекти се провеждат постоянни вътрешни експертни
          съвети
        </p>
        <p>
          Архитектурната група е с дългогодишен професионален опит и е изградила авторитета си с множество архитектурни
          проекти и участия в международни и национални изложби и конкурси, както и публикации в професионални вестници
          и списания .Част от колектива е провел самостоятелна изложба на архитектурните си проекти в град Санкт
          Петербург по време на &quot;Дните на Пловдив&quot; в града.
        </p>
        <p>
          Арх. Мария Стоянова е участвала в международните архитектурни срещи – специализации във Франция през 1990г. ,
          а арх. Екатерина Генева – в Канада, арх. Володя Стоянов и арх. Величко Куртев – в Грузия.
        </p>

        <h2>ПАРТНЬОРИ</h2>

        {partners.map((partner, i) => (
          <section key={i}>
            <Link to="/за-нас/партньори/">
              <h3>{partner.name}</h3>
            </Link>
          </section>
        ))}
      </Content>
    </Layout>
  );
};

export default History;
